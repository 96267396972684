const Role = {
	SupervisorDIGEX: 'SupervisorDIGEX',  					// Accede a todo excepto a /Operador/Facturacion/Facturacion
	ADMUsuarios: 'ADMUsuarios',  							// Admin/ABMUsuarios
	ABMRoles: 'ABMRoles',  									// Admin/ABMUsuarios (Restringir editar roles)
	BajaUsuarios: 'BajaUsuarios',  							// Admin/ABMUsuarios (Permite dar de baja usuarios)
	AltaUsuarios: 'AltaUsuarios',  							// Admin/ABMUsuarios (Permite dar de alta usuarios)
	ABMClientes: 'ABMClientes',  							// Admin/ABMClientes - /Admin/DetalleCliente
	ABMContrato: 'ABMContrato', 							// Operador/Facturacion/ABMContrato
	ABMNovedades: 'ABMNovedades',  							// Admin/ABMNovedades
	AltaPreUsuario: 'AltaPreUsuario',  						// Operador/Comercial/AltaUsuario
	EjecutivaCuenta: 'EjecutivaCuenta', 					// Nada creo
	ValidacionFactura: 'ValidacionFactura', 				// Cliente/ValidacionFactura - /Cliente/InfoValidados
	ValidacionFacturaInterna: 'ValidacionFacturaInterna',   // Cliente/ValidacionFactura - /Cliente/InfoValidados
	ZySFacturacion: 'ZySFacturacion', 						// Cliente/Facturacion/Facturacion
	CuentaCorriente: 'CuentaCorriente', 					// Cliente/CuentaCorriente
	ControlValidaciones: 'ControlValidaciones', 			// Operador/ControlValidaciones
	DashboardDespachante: 'DashboardDespachante', 			// Cliente/Desktop
	Documentacion: 'Documentacion', 						// Cliente/Documentacion
	ConsultarHistorialLegajo: 'ConsultarHistorialLegajo',	// Operador/Pymoa
	VerFamilias: 'VerFamilias', 							// ViewFamilias (Afecta a Reportes, Documentacion, ValidacionFactura)
	CarpetasDigitalizacion: 'CarpetasDigitalizacion',
															// Operador/Desktop
															// Operador/Digitalizar
															// Operador/DigitalizarPdf
															// Operador/ProcesarCarpeta

	ConfirmarCarpeta: 'ConfirmarCarpeta',					// Operador/Confirmar
	ConsultarGuia: 'ConsultarGuia',							// Cliente/ConsultarGuia - /Correo/ConsultarGuia
	GenerarGuia: 'GenerarGuia', 							// Cliente/GenerarGuia
	GenerarGuiaSF: 'GenerarGuiaSF', 						// Cliente/GenerarGuia
	GenerarGuiaLibreAduana: 'GenerarGuiaLibreAduana', 		// Cliente/GenerarGuia
	RetiraGuia: 'RetiraGuia', 								// Cliente/GenerarGuia (Si retira o va por andreani)
	GuiaRecepcionar: 'GuiaRecepcionar', 					// Operador/Validar2 - /Correo/Validar2
	ModificarEstadoGuia: 'ModificarEstadoGuia', 			// Operador/Pymoa
	ProgramarRetiro: 'ProgramarRetiro', 					// Operador/ProgramarRetiro
	PedidoLegajo: 'PedidoLegajo',							// Cliente/PedidoLegajo
	Seguimiento: 'Seguimiento', 							// Cliente/Seguimiento
	ReportesPremium: 'ReportesPremium',
	ReportesPremiumFull: 'ReportesPremiumFull',
															// Operador/DesktopIMEXPremium
															// Cliente/Reporte/OperacionesMes
															// Cliente/Reporte/OperacionesProducto
															// Cliente/Reporte/OperacionesDespachante
															// Cliente/InstructivoIMPremium

	ReporteBungeImportacion: 'ReporteBungeImportacion', 	// Cliente/Reporte/ReportesB
	ReporteBungeExportacion: 'ReporteBungeExportacion', 	// Cliente/Reporte/ReportesB
	ReporteBungeMovimiento: 'ReporteBungeMovimiento', 		// Cliente/Reporte/ReportesB
	ReporteBungeSaldo: 'ReporteBungeSaldo', 				// Cliente/Reporte/ReportesB
	ReporteBungeBoleta: 'ReporteBungeBoleta', 				// Cliente/Reporte/ReportesB
	ReporteBungeDetallado: 'ReporteBungeDetallado',			// Cliente/Reporte/ReportesB
	ReporteLDC: 'ReporteLDC', 								// Cliente/Reporte/ReporteLDC
	ReporteL: 'ReporteL',	 								// Cliente/Reporte/ReporteL
	InstructivoIM: 'InstructivoIM', 						// Cliente/InstructivoIM - /Cliente/InstructivoIMPremium (Se agrega si tiene el rol de Premium)
	InstructivoDE: 'InstructivoDE', 						// Cliente/InstructivoDE
	DJVEBunge: 'DJVEBunge', 								// Cliente/Bunge - /Cliente/DigitalizarDjve
	ConsultarDjve: 'ConsultarDjve', 						// Cliente/ConsultarDjve 
	Barrido: 'Barrido', 									// Operador/Pymoa
	AvisoDIGI: 'AvisoDIGI',									// Operador/Pymoa
	ReprocesarDIGI: 'ReprocesarDIGI', 						// Operador/Pymoa
	ABMFestivos: 'ABMFestivos',								// Operador/ABMFestivos
	AltaPreUsuarioExterno: 'AltaPreUsuarioExterno',			// Cliente/AltaPreUsuarioExterno
	GeneradorDeUsuarios:'GeneradorDeUsuarios',				// Cliente/GeneradorDeUsuarios
	ClaveFiscal: 'ClaveFiscal', 						// Cliente/ClaveFiscal
}

export default Role;